import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pay-status',
  templateUrl: './pay-status.component.html',
  styleUrls: ['./pay-status.component.scss'],
})
export class PayStatusComponent {
  constructor(private router: Router) {}

  goToHome() {
    this.router.navigate(['/'], { replaceUrl: true });
  }
}
