import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  private isBrowser = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  getIsBrowser() {
    return this.isBrowser.asObservable();
  }

  setIsBrowser(value: boolean) {
    this.isBrowser.next(value);
  }
}
