import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigData } from '../models';
import { ConfigService } from './config.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  configData!: ConfigData;
  defaultLanguage: any;
  selectedLanguage: any;

  constructor(
    private translateService: TranslateService,
    public configService: ConfigService,
    private localStorageService: LocalStorageService
  ) {
    // Subscribe to local config changes and initialize language settings
    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.configData = configData;
      this.defaultLanguage = this.configData?.trsWebAppConfig?.languages?.default;
    });

    // Retrieve selected language from local storage
    this.selectedLanguage = this.localStorageService.getItem('language');

    // Set up language preferences
    translateService.addLangs(['en', 'es', 'fr']);
    translateService.setDefaultLang(this.defaultLanguage);
    translateService.use(this.selectedLanguage || this.defaultLanguage);
  }

  /**
   * Sets the application language.
   *
   * @param lang The language code to set.
   */
  setLanguage(lang: string): void {
    this.translateService.use(lang);
  }

  /**
   * Retrieves the currently selected language.
   *
   * @returns The currently selected language code.
   */
  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }
}
