import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigData } from '../../models';
import { ConfigService } from '../../service';
import { AlertService } from '../../service/alert.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  configData!: ConfigData;
  address: string | undefined;
  currentYear: number;
  socialLinks!: {
    instagram?: string | undefined;
    linkedin?: string | undefined;
    twitter?: string | undefined;
  };

  constructor(
    private alertService: AlertService,
    private configService: ConfigService,
    private router: Router,
    public translate: TranslateService
  ) {
    // Set the currentYear property to the current year using JavaScript's Date object
    this.currentYear = new Date().getFullYear();

    // Retrieves local configuration data
    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.configData = configData;

      // Retrieves social links and address from configuration data
      this.socialLinks = this.configData?.trsWebAppConfig?.social ?? {};
      this.address = this.configData?.trsWebAppConfig?.address;
    });
  }

  /**
   * Redirects to the specified social platform URL in a new tab.
   *
   * @param url The URL of the social platform.
   */
  redirectToSocialPlatform(url: string) {
    const socialLink = this.socialLinks[url as keyof typeof this.socialLinks];

    if (socialLink) {
      // Opens the social platform URL in a new tab
      window.open(socialLink, '_blank');
    } else {
      // Logs warning for unhandled social platform
      console.warn('Unhandled social platform:', url);
    }
  }
}
