import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigData, Plan, SubscriptionDetails } from '../../models';
import {
  ConfigService,
  LanguageService,
  LocalStorageService,
  SubscriptionService,
} from '../../service';
import { AlertService } from '../../service/alert.service';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogService } from '../dialog/dialog.service';

export interface SubscriptionDetailsResponse {
  message: string;
  subscription: {
    planName: string;
    sellingPrice: number;
    qrImg: string;
    validity: number;
    data: string;
    expiredDate: string;
    currency: string;
  };
}

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit {
  transactionId!: string;
  orderId!: string;
  reservedId!: string;
  dialogRef: DialogComponent | undefined;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  inProgress: boolean = false;
  orderObj: any;
  agentId: string | undefined;
  subscriptionDetails!: SubscriptionDetails;
  currencySymbol!: string;
  selectedPlanDetails!: Plan;
  configData!: ConfigData;

  constructor(
    private viewContainer: ViewContainerRef,
    private dialogService: DialogService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private configService: ConfigService,
    private alertService: AlertService,
    private languageService: LanguageService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
    this.inProgress = true;

    // Subscribe to local configuration data
    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.configData = configData;
      this.agentId = this.configData?.agentId;
    });
  }

  ngOnInit() {
    // Retrieve selected plan details and order information from local storage
    this.selectedPlanDetails = JSON.parse(this.localStorageService.getItem('planDetails') ?? '{}');
    const order = this.localStorageService.getItem('orderObj');
    if (order != null) {
      this.orderObj = JSON.parse(order);
    }

    // If transaction ID exists, fetch subscription details; otherwise, unreserve inventories
    if (this.transactionId && this.transactionId != '') {
      setTimeout(() => {
        this.getSubscriptionDetails();
      }, 2000);
    } else {
      this.unreservedInventories(this.orderObj?.reservedId);
    }
  }

  /**
   * Fetches subscription details based on the transaction ID.
   */
  getSubscriptionDetails() {
    this.inProgress = true;

    this.subscriptionService
      .getSubscriptionDetails(this.transactionId, this.languageService.getCurrentLanguage())
      .subscribe(
        (res: SubscriptionDetailsResponse) => {
          this.subscriptionDetails = res?.subscription;
          this.currencySymbol = getCurrencySymbol(this.subscriptionDetails?.currency, 'narrow');
          this.inProgress = false;
        },
        (error: any) => {
          this.inProgress = false;
          this.close();
          this.alertService.error(error.error.message, error.status);
        }
      );
  }

  /**
   * Closes the payment status popup.
   *
   * @param type The type of action triggering the close.
   */
  close(type?: string) {
    if (this.dialogRef) {
      this.dialogRef.close.emit();
    }
    if (!this.transactionId && type && type == 'close') {
      this.router.navigate(['/order-summary'], { replaceUrl: true });
    } else {
      this.localStorageService.removeItem('orderObj');
      this.router.navigate(['/'], { replaceUrl: true });
      this.localStorageService.removeItem('planDetails');
    }
  }

  /**
   * Retries the payment process.
   */
  retryPayment() {
    this.checkEsimAvailability(this.orderObj);
  }

  /**
   * Initiates the checkout process.
   *
   * @param userDetails The user details for the checkout.
   */
  checkOut(userDetails: any) {
    this.inProgress = true;
    this.localStorageService.setItem('orderObj', JSON.stringify(userDetails));
    this.subscriptionService
      .subscribePlan(this.agentId, userDetails?.reservedId, userDetails)
      .catch((err: any) => {
        this.alertService.error(err.error.message, err.status);
        this.inProgress = false;
      });
  }

  /**
   * Checks the availability of eSIM.
   *
   * @param userDetails The user details for the eSIM availability check.
   */
  checkEsimAvailability(userDetails: any) {
    this.inProgress = true;
    const userObj = {
      displayName: userDetails.displayName,
      email: userDetails.email,
      planId: this.selectedPlanDetails._id,
      country: this.selectedPlanDetails?.selectedCountry,
      reservedId: null,
    };

    this.subscriptionService
      .checkEsimAvailability(this.agentId, userObj, this.languageService.getCurrentLanguage())
      .subscribe(
        (res: any) => {
          if (res?.reservedId) {
            this.reservedId = res?.reservedId;
            userObj.reservedId = res?.reservedId;
            this.checkOut(userObj);
          }
        },
        (error: any) => {
          this.inProgress = false;
          this.alertService.error(error?.error?.message);
        }
      );
  }

  /**
   * Unreserve inventories for a given reservation ID.
   *
   * @param reservedId The ID of the reservation to unreserve inventories for.
   */
  unreservedInventories(reservedId: string) {
    this.inProgress = true;
    this.subscriptionService.unreservedInventories(reservedId).subscribe(
      () => {
        this.inProgress = false;
      },
      (error: any) => {
        this.inProgress = false;
        this.alertService.error(error.error.message, error.status);
      }
    );
  }

  /**
   * Downloads the QR code from the provided URL.
   *
   * @param qrUrl The URL of the QR code to download.
   */
  downloadQr(qrUrl: string) {
    fetch(qrUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = 'qrCode.png';

        // Append the link to the document and trigger a click
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);

        // Release the object URL
        window.URL.revokeObjectURL(url);
      });
  }
}
