import { Injectable } from '@angular/core';
import { DialogService } from '../component/dialog/dialog.service';
import { AlertComponent } from '../dialog/alert/alert.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialogService: DialogService) {}

  /**
   * Displays an error message in a modal dialog.
   *
   * @param message The error message to display.
   * @param statusCode Optional HTTP status code associated with the error.
   * @returns An observable subscription.
   */
  error(message: string, statusCode?: number) {
    let customTitle: string;

    if (statusCode && statusCode == 401) {
      customTitle = 'Info';
    } else if (statusCode && statusCode == 500) {
      customTitle = 'Warning';
    } else {
      customTitle = 'failed';
    }

    return (
      this.dialogService
        .openModal(AlertComponent, {
          // cssClass: 'modal-sm',
          context: { title: customTitle, body: message },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
        .subscribe((data: any) => {
          // Do something with the data if needed
        })
    );
  }

  /**
   * Displays a success message in a modal dialog.
   *
   * @param message The success message to display.
   * @param type Optional type of success message.
   * @param title Optional title for the success message.
   * @returns An observable subscription.
   */
  success(message: string, type?: string, title?: string) {
    title = title ?? 'success';
    return this.dialogService.openModal(AlertComponent, {
      // cssClass: 'modal-sm',
      context: { title: title, body: message, type: type },
    });
  }

  /**
   * Displays a warning message in a modal dialog.
   *
   * @param message The warning message to display.
   * @returns An observable subscription.
   */
  warning(message: string) {
    const customTitle = 'Warning';
    return this.dialogService.openModal(AlertComponent, {
      // cssClass: 'modal-sm',
      context: { title: customTitle, body: message },
    });
  }
}
