<!-- Success Modal -->
<ng-container *ngIf="title === 'success' || type === 'success'">
  <div class="success-modal">
    <div class="modal-dialog modal-dialog-centered m-0 modal-cust">
      <div class="modal-content">
        <div class="modal-body">
          <ng-container>
            <div class="success-modal-check-icon-container">
              <div class="success-modal-check-icon-container-inner">
                <svg
                  width="43"
                  height="33"
                  viewBox="0 0 43 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.1374 23.4034L35.9121 1.62863C36.5887 0.952015 37.4498 0.613708 38.4955 0.613708C39.5412 0.613708 40.4023 0.952015 41.079 1.62863C41.7556 2.30525 42.0939 3.16639 42.0939 4.21207C42.0939 5.25775 41.7556 6.1189 41.079 6.79552L16.7208 31.1537C15.9827 31.8918 15.1215 32.2609 14.1374 32.2609C13.1532 32.2609 12.292 31.8918 11.5539 31.1537L1.95828 21.558C1.28167 20.8814 0.943359 20.0203 0.943359 18.9746C0.943359 17.9289 1.28167 17.0678 1.95828 16.3912C2.6349 15.7145 3.49605 15.3762 4.54172 15.3762C5.5874 15.3762 6.44855 15.7145 7.12517 16.3912L14.1374 23.4034Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="t-font-semibold t-color-dark text-center success-modal-head">
                  {{ 'alert.' + title.toLowerCase() | translate }}
                </p>

                <p
                  class="t-font-regular t-color-dark success-modal-subhead text-center"
                  [innerHTML]="body"
                ></p>

                <button
                  class="btn w-100 border-radius-100 t-bg-primary t-color-white sf-pro-regular"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  (click)="close()"
                >
                  {{ 'alert.close' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- error modal -->
<ng-container *ngIf="title === 'Error' || title === 'Something went wrong!' || title === 'failed'">
  <div class="error-modal">
    <div class="modal-dialog modal-dialog-centered m-0">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-end">
            <button
              type="button"
              class="btn-close ml-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
              (click)="close()"
            ></button>
          </div>
          <div class="error-modal-check-icon-container">
            <div class="error-modal-check-icon-container-inner">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="184"
                height="184"
                viewBox="0 0 184 184"
                fill="none"
              >
                <circle cx="92" cy="92" r="92" fill="#D92D20" fill-opacity="0.1" />
                <circle
                  cx="91.9985"
                  cy="92.0024"
                  r="67.9477"
                  fill="url(#paint0_linear_6019_18734)"
                />
                <mask
                  id="mask0_6019_18734"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="57"
                  y="57"
                  width="70"
                  height="70"
                >
                  <rect x="57" y="57" width="70" height="70" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_6019_18734)">
                  <path
                    d="M92 107C92.85 107 93.5625 106.713 94.1375 106.138C94.7125 105.562 95 104.85 95 104C95 103.15 94.7125 102.438 94.1375 101.863C93.5625 101.288 92.85 101 92 101C91.15 101 90.4375 101.288 89.8625 101.863C89.2875 102.438 89 103.15 89 104C89 104.85 89.2875 105.562 89.8625 106.138C90.4375 106.713 91.15 107 92 107ZM89 95H95V77H89V95ZM92 122C87.85 122 83.95 121.212 80.3 119.637C76.65 118.062 73.475 115.925 70.775 113.225C68.075 110.525 65.9375 107.35 64.3625 103.7C62.7875 100.05 62 96.15 62 92C62 87.85 62.7875 83.95 64.3625 80.3C65.9375 76.65 68.075 73.475 70.775 70.775C73.475 68.075 76.65 65.9375 80.3 64.3625C83.95 62.7875 87.85 62 92 62C96.15 62 100.05 62.7875 103.7 64.3625C107.35 65.9375 110.525 68.075 113.225 70.775C115.925 73.475 118.062 76.65 119.637 80.3C121.212 83.95 122 87.85 122 92C122 96.15 121.212 100.05 119.637 103.7C118.062 107.35 115.925 110.525 113.225 113.225C110.525 115.925 107.35 118.062 103.7 119.637C100.05 121.212 96.15 122 92 122ZM92 116C98.7 116 104.375 113.675 109.025 109.025C113.675 104.375 116 98.7 116 92C116 85.3 113.675 79.625 109.025 74.975C104.375 70.325 98.7 68 92 68C85.3 68 79.625 70.325 74.975 74.975C70.325 79.625 68 85.3 68 92C68 98.7 70.325 104.375 74.975 109.025C79.625 113.675 85.3 116 92 116Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_6019_18734"
                    x1="128.678"
                    y1="24.0547"
                    x2="69.7501"
                    y2="153.336"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#D92D20" />
                    <stop offset="1" stop-color="#D92D20" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="t-font-semibold t-color-dark text-center error-modal-head">
                {{ 'alert.' + title.toLowerCase() | translate }}
              </p>
              <p
                class="t-font-regular t-color-dark text-center error-modal-subhead"
                [innerHTML]="body"
              ></p>
              <button
                class="btn help-submit-btn err-btn payment-plan-submit-btn w-100 sf-pro-regular t-color-white mt-auto border-radius-100"
                data-bs-dismiss="modal"
                aria-label="Close"
                (click)="close()"
              >
                {{ 'alert.close' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
