<div class="t-bg-light">
  <ng-container>
    <div class="container">
      <app-page-title
        [title]="'page_title.payment_status.title' | translate"
        backToRoute="/"
      ></app-page-title>
      <div class="payment-status-container">
        <div class="payment-status-container-form t-bg-white">
          <div class="text-center">
            <svg
              width="122"
              height="122"
              viewBox="0 0 122 122"
              fill="none"
              xmlns="http://wwwapp-.w3.org/2000/svg"
            >
              <circle cx="61" cy="61" r="61" fill="#6365EF" fill-opacity="0.1" />
              <circle cx="61.0005" cy="60.9991" r="45.0523" fill="url(#paint0_linear_4014_8186)" />
              <path
                d="M55.5559 66.0747L72.806 48.8245C73.342 48.2885 74.0242 48.0205 74.8526 48.0205C75.681 48.0205 76.3632 48.2885 76.8993 48.8245C77.4353 49.3606 77.7033 50.0428 77.7033 50.8712C77.7033 51.6996 77.4353 52.3818 76.8993 52.9178L57.6025 72.2145C57.0178 72.7993 56.3356 73.0916 55.5559 73.0916C54.7762 73.0916 54.094 72.7993 53.5093 72.2145L45.9075 64.6128C45.3715 64.0768 45.1035 63.3945 45.1035 62.5662C45.1035 61.7378 45.3715 61.0555 45.9075 60.5195C46.4436 59.9835 47.1258 59.7155 47.9542 59.7155C48.7826 59.7155 49.4648 59.9835 50.0008 60.5195L55.5559 66.0747Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_4014_8186"
                  x1="85.3208"
                  y1="15.9468"
                  x2="46.2489"
                  y2="101.666"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#6365EF" />
                  <stop offset="1" stop-color="#6365EF" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>

            <h1 class="t-color-dark t-font-semibold payment-status-form-head mt-5">
              {{ 'pay_status.title' | translate }}
            </h1>
            <p class="t-color-dark t-font-regular payment-status-form-subhead">
              {{ 'pay_status.sub_title' | translate }}
            </p>
            <button
              (click)="goToHome()"
              type="button"
              class="btn pay-submit-btn sf-pro-regular t-color-white t-bg-primary mb-5 text-center"
            >
              {{ 'pay_status.submit_button' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
