<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-3">
        <div class="footer-brand-container">
          <a class="footer-brand t-navbar-brand t-font-semibold t-logo-color" [routerLink]="'/'">{{
            configData?.trsWebAppConfig?.name
          }}</a>
          <p class="footer-text sf-pro-regular mt-3"><span>© </span> {{currentYear}} GlowingBud LLC</p>
        </div>
      </div>
      <div class="col-6 col-lg-3 col-xl-2">
        <div class="footer-details-container">
          <p class="footer-text t-font-semibold">
            {{ 'footer.links' | translate }}
          </p>
          <a
            class="footer-text sf-pro-regular cursor-pointer text-decoration-none"
            routerLink="/privacy-policy"
          >
            {{ 'footer.privacy_policy' | translate }}
          </a>
        </div>
      </div>
      <div class="col-6 col-lg-3 col-xl-2">
        <div class="footer-details-container">
          <p class="footer-text t-font-semibold">
            {{ 'footer.contact_us' | translate }}
          </p>
          <a
            class="footer-text sf-pro-regular cursor-pointer text-decoration-none"
            [routerLink]="'/help'"
            >{{ 'footer.support' | translate }}</a
          >
        </div>
      </div>
      <div class="col-6 col-lg-4 col-xl-3">
        <div class="footer-details-container">
          <p class="footer-text t-font-semibold">
            {{ 'footer.our_address' | translate }}
          </p>
          <p class="footer-text sf-pro-regular footer-address">{{ address }}</p>
        </div>
      </div>
      <div class="col-6 col-lg-2 col-xl-2">
        <div class="footer-details-container">
          <p class="footer-text t-font-semibold">
            {{ 'footer.follow_us' | translate }}
          </p>
          <div class="social-icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="69"
              height="17"
              viewBox="0 0 69 17"
              fill="none"
              class="cursor-pointer"
            >
              <path
                (click)="redirectToSocialPlatform('linkedin')"
                d="M38.3333 1C38.7754 1 39.1993 1.17559 39.5118 1.48816C39.8244 1.80072 40 2.22464 40 2.66667L40 14.3333C40 14.7754 39.8244 15.1993 39.5118 15.5118C39.1993 15.8244 38.7754 16 38.3333 16H26.6667C26.2246 16 25.8007 15.8244 25.4882 15.5118C25.1756 15.1993 25 14.7754 25 14.3333L25 2.66667C25 2.22464 25.1756 1.80072 25.4882 1.48816C25.8007 1.17559 26.2246 1 26.6667 1H38.3333ZM37.9167 13.9167L37.9167 9.5C37.9167 8.77949 37.6304 8.0885 37.121 7.57903C36.6115 7.06955 35.9205 6.78333 35.2 6.78333C34.4917 6.78333 33.6667 7.21667 33.2667 7.86667L33.2667 6.94167H30.9417L30.9417 13.9167H33.2667L33.2667 9.80833C33.2667 9.16667 33.7833 8.64167 34.425 8.64167C34.7344 8.64167 35.0312 8.76458 35.25 8.98338C35.4687 9.20217 35.5917 9.49891 35.5917 9.80833L35.5917 13.9167H37.9167ZM28.2333 5.63333C28.6046 5.63333 28.9607 5.48583 29.2233 5.22328C29.4858 4.96073 29.6333 4.60464 29.6333 4.23333C29.6333 3.45833 29.0083 2.825 28.2333 2.825C27.8598 2.825 27.5016 2.97338 27.2375 3.23749C26.9734 3.5016 26.825 3.85982 26.825 4.23333C26.825 5.00833 27.4583 5.63333 28.2333 5.63333ZM29.3917 13.9167L29.3917 6.94167H27.0833L27.0833 13.9167H29.3917Z"
                fill="#303030"
              />
              <path
                (click)="redirectToSocialPlatform('twitter')"
                d="M68.75 2.85294C68.0599 3.17721 67.316 3.39029 66.5452 3.49221C67.3339 3.00118 67.9434 2.22294 68.2302 1.28721C67.4863 1.75044 66.6617 2.07471 65.7923 2.26C65.0842 1.46324 64.0894 1 62.9601 1C60.8538 1 59.133 2.77882 59.133 4.97456C59.133 5.28956 59.1689 5.59529 59.2316 5.8825C56.0409 5.71574 53.1997 4.13147 51.3086 1.73191C50.9769 2.31559 50.7887 3.00118 50.7887 3.72382C50.7887 5.10426 51.4609 6.32721 52.5006 7.02206C51.8642 7.02206 51.2727 6.83676 50.7529 6.55882L50.7529 6.58662C50.7529 8.51368 52.0794 10.1257 53.836 10.4871C53.272 10.6466 52.6799 10.6688 52.1062 10.5519C52.3497 11.3417 52.8264 12.0328 53.4695 12.528C54.1125 13.0232 54.8896 13.2977 55.6913 13.3128C54.3322 14.425 52.6476 15.0261 50.9142 15.0175C50.6095 15.0175 50.3047 14.999 50 14.9619C51.7029 16.0922 53.7285 16.75 55.8975 16.75C62.9601 16.75 66.8409 10.6909 66.8409 5.43779C66.8409 5.26176 66.8409 5.095 66.832 4.91897C67.5848 4.36309 68.2302 3.65897 68.75 2.85294Z"
                fill="#303030"
              />
              <path
                (click)="redirectToSocialPlatform('instagram')"
                d="M9.9 8.25C9.9 8.72468 9.75924 9.18869 9.49553 9.58337C9.23181 9.97805 8.85698 10.2857 8.41844 10.4673C7.9799 10.649 7.49734 10.6965 7.03178 10.6039C6.56623 10.5113 6.13859 10.2827 5.80294 9.94706C5.4673 9.61141 5.23872 9.18377 5.14612 8.71822C5.05351 8.25266 5.10104 7.7701 5.28269 7.33156C5.46434 6.89302 5.77195 6.51819 6.16663 6.25447C6.56131 5.99076 7.02533 5.85 7.5 5.85C8.13591 5.85198 8.74521 6.10547 9.19487 6.55513C9.64453 7.00479 9.89802 7.61409 9.9 8.25ZM15 4.95L15 11.55C15 12.6639 14.5575 13.7322 13.7698 14.5198C12.9822 15.3075 11.9139 15.75 10.8 15.75H4.2C3.08609 15.75 2.0178 15.3075 1.23015 14.5198C0.442499 13.7322 -8.67504e-08 12.6639 -7.86395e-08 11.55L-3.0582e-08 4.95C-2.24712e-08 3.83609 0.442499 2.7678 1.23015 1.98015C2.0178 1.1925 3.08609 0.75 4.2 0.75H10.8C11.9139 0.75 12.9822 1.1925 13.7698 1.98015C14.5575 2.7678 15 3.83609 15 4.95ZM11.1 8.25C11.1 7.53799 10.8889 6.84196 10.4933 6.24995C10.0977 5.65793 9.53547 5.19651 8.87766 4.92403C8.21985 4.65156 7.49601 4.58027 6.79768 4.71917C6.09934 4.85808 5.45788 5.20095 4.95442 5.70442C4.45095 6.20788 4.10808 6.84934 3.96917 7.54768C3.83027 8.24601 3.90156 8.96985 4.17403 9.62766C4.44651 10.2855 4.90793 10.8477 5.49995 11.2433C6.09196 11.6389 6.78799 11.85 7.5 11.85C8.45478 11.85 9.37045 11.4707 10.0456 10.7956C10.7207 10.1205 11.1 9.20478 11.1 8.25ZM12.3 4.35C12.3 4.172 12.2472 3.99799 12.1483 3.84999C12.0494 3.70198 11.9089 3.58663 11.7444 3.51851C11.58 3.45039 11.399 3.43257 11.2244 3.46729C11.0498 3.50202 10.8895 3.58774 10.7636 3.7136C10.6377 3.83947 10.552 3.99984 10.5173 4.17442C10.4826 4.349 10.5004 4.52996 10.5685 4.69442C10.6366 4.85887 10.752 4.99943 10.9 5.09832C11.048 5.19722 11.222 5.25 11.4 5.25C11.6387 5.25 11.8676 5.15518 12.0364 4.9864C12.2052 4.81761 12.3 4.5887 12.3 4.35Z"
                fill="#303030"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
