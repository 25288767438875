<div
  class="t-loader-container"
  [style.minHeight]="'calc(100vh - ' + minHeight + ')'"
  [style.backgroundImage]="'url(' + backgroundImage + ')'"
>
  <div class="t-loader-inner-container">
    <ng-lottie [options]="options" width="320px"></ng-lottie>
    <div class="t-loader-text" *ngIf="loaderText">{{ loaderText }}</div>
  </div>
</div>

