import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { DialogComponent } from '../../component/dialog/dialog.component';
import { DialogService } from '../../component/dialog/dialog.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  title = 'Something went wrong!';
  body = 'Oops, it seems like we have run into an error. Please rectify the problems and try again';
  dialogRef: DialogComponent;
  titleArr: string[] = ['Successful!', 'Error', 'Info', 'Warning'];
  type!: string;
  buttonGroup = [{ cssClass: 'ms-auto', title: 'Close', value: false }];

  constructor(
    private viewContainer: ViewContainerRef,
    private dialogService: DialogService
  ) {
    // Inject DialogComponent into view container
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  ngOnInit(): void {
    // Set title, body, and type based on dialog context
    this.title = this.dialogRef?.context?.title;
    this.body = this.dialogRef?.context?.body;
    this.type = this.dialogRef?.context?.type;

    // Customize button group based on title or type
    switch (true) {
      case this.title == 'Error' || this.title == 'failed':
        this.buttonGroup = this.buttonGroup.filter((x) => x.title == 'Close');
        break;
      case this.title == 'Request  Success!' || this.type == 'success':
        this.buttonGroup = this.buttonGroup.filter((x) => x.title == 'Close');
        break;
    }
  }

  /**
   * Close the dialog.
   *
   * @param openUploadInvenotry Optional parameter for opening upload inventory.
   */
  close(): void {
    this.dialogRef.close.emit();
  }
}
