import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  /**
   * Filters an array based on a search term.
   *
   * @param value The array to filter.
   * @param searchTerm The search term to filter by.
   * @param keyIndex The index of the key to search within each item (optional).
   * @returns The filtered array or null if the input array is undefined.
   */
  transform(
    value: Array<any> | undefined,
    searchTerm = '',
    keyIndex: any = undefined
  ): Array<any> | null {
    if (!value) return null;
    if (!searchTerm) return value;
    return value?.filter((item: any) => {
      const searchValue = keyIndex ? item[keyIndex] : item;
      return JSON.stringify(searchValue)?.toLowerCase()?.includes(searchTerm?.toLowerCase());
    });
  }
}
