<app-navbar></app-navbar>


<div
  class="t-loader-container error-page-conatiner"
  style="background-image: url('/assets/loaders/404.png')"
>
  <div class="error-page-inner-container">
    <p class="error-page-heading">{{ 'error_page.heading' | translate }}</p>
    <p class="error-page-subheading">
      {{ 'error_page.subheading' | translate }}
    </p>
    <p class="error-page-supporting-text">
      {{ 'error_page.supporting_text' | translate }}
    </p>
    <a
      class="cursor-pointer error-page-back-btn"
      (click)="goBack()"
      (keydown)="handleKeyDown($event)"
      tabindex="0"
      >{{ 'error_page.back_btn' | translate }}</a
    >
  </div>
</div>

<app-footer></app-footer>

