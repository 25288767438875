import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ConfigData } from '../global/models';
import { ConfigService, LanguageService, LocalStorageService } from '../global/service';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
})
export class CoreComponent {
  configData!: ConfigData;
  private renderer!: Renderer2;

  constructor(
    private configService: ConfigService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    public languageService: LanguageService,
    private location: Location,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: Document,
    rendererFactory: RendererFactory2
  ) {
    // Initialize the renderer
    this.renderer = rendererFactory.createRenderer(null, null);

    // Subscribe to local config service to get configuration data
    this.configService.getLocalConfig().subscribe((configData: ConfigData) => {
      if (configData != undefined && configData != null) {
        // Check if configData is not empty
        this.configData = Object?.keys(configData)?.length > 0 ? configData : {};

        // Set the page title based on configuration data
        this.titleService.setTitle(this.configData?.trsWebAppConfig?.name ?? '');

        // Update favicon based on configuration data
        this.updateFavicon(
          `${configData?.trsWebAppConfig?.s3BucketAssets}/${configData?.customerId}/favicon.ico`,
          'assets/images/common/favicon.ico'
        );
      }
    });
  }

  /**
   * Navigates back in the browser history.
   */
  goBack(): void {
    this.location.back();
  }

  /**
   * Updates the favicon of the application.
   *
   * @param url The URL of the favicon.
   * @param defaultUrl The default URL of the favicon.
   */
  updateFavicon(url: string, defaultUrl: string): void {
    const faviconElement = this._document.getElementById('appFavicon');

    if (!faviconElement) {
      console.error('Favicon element with ID "appFavicon" not found.');
      return;
    }

    // Use Image object to check if the URL points to a valid image
    const img = new Image();
    img.onload = () => {
      // URL points to a valid image, update the favicon with the dynamic URL
      this.renderer.setAttribute(faviconElement, 'href', url);
    };
    img.onerror = () => {
      // URL does not point to a valid image, use the default URL
      this.renderer.setAttribute(faviconElement, 'href', defaultUrl);
    };

    // Set the source to trigger the image load event
    img.src = url;
  }
}
