<div class="t-bg-light">
  <ng-container>
    <div class="container">
      <app-page-title
        [title]="'page_title.payment_link.title' | translate"
        backToRoute="/"
      ></app-page-title>
      <div class="payment-status-container">
        <div class="payment-status-container-form t-bg-white">
          <div class="text-center">
            <svg
              width="122"
              height="122"
              viewBox="0 0 122 122"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="61" cy="61" r="61" fill="#D92D20" fill-opacity="0.1" />
              <circle cx="61.0005" cy="60.9991" r="45.0523" fill="url(#paint0_linear_1488_2971)" />
              <path
                d="M86.2344 76.7653H89.5914C91.2998 76.7653 92.6849 75.3804 92.6849 73.6718V70.2681"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M92.6849 66.1634V61.6411C92.6849 59.9327 91.2999 58.5476 89.5914 58.5476H86.2344"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M32.3093 76.7654H35.6663V58.5476H32.3093C30.6009 58.5476 29.2158 59.9325 29.2158 61.6411V73.672C29.2158 75.3804 30.6007 76.7654 32.3093 76.7654Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M70.0594 37.1759C71.8608 37.1759 73.321 35.7156 73.321 33.9143C73.321 32.113 71.8608 30.6527 70.0594 30.6527C68.2581 30.6527 66.7979 32.113 66.7979 33.9143C66.7979 35.7156 68.2581 37.1759 70.0594 37.1759Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M70.0586 37.176V44.593"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M52.8174 52.8556L54.2955 56.2438C54.6414 57.0364 55.424 57.5489 56.2887 57.5489H65.4845C66.3493 57.5489 67.1319 57.0364 67.4777 56.2438L68.9558 52.8556"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M49.6494 64.2638L53.5769 68.1913"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M49.6494 68.2158L53.5769 64.2882"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M72.2497 64.2638L68.3223 68.1913"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M72.2497 68.2158L68.3223 64.2882"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M53.8877 76.7654C57.5755 72.4947 64.1955 72.4947 67.8834 76.7654"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M81.0585 47.4096C78.79 45.6446 75.9391 44.5929 72.8423 44.5929H49.0567C41.6608 44.5929 35.665 50.5885 35.665 57.9846V77.3285C35.665 84.7244 41.6607 90.7202 49.0567 90.7202H72.8425C80.2384 90.7202 86.2341 84.7246 86.2341 77.3285V57.9847C86.2341 55.172 85.3663 52.5623 83.8848 50.4074"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M41.4365 74.3728V78.7883C41.4365 81.2035 43.3944 83.1613 45.8095 83.1613H75.9639C78.3791 83.1613 80.3369 81.2035 80.3369 78.7883V64.6285C80.3369 58.0555 75.0084 52.7272 68.4355 52.7272H53.338C46.765 52.7272 41.4367 58.0556 41.4367 64.6285V70.2681"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1488_2971"
                  x1="85.3208"
                  y1="15.9468"
                  x2="46.2489"
                  y2="101.666"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#D92D20" />
                  <stop offset="1" stop-color="#D92D20" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>

            <h1 class="t-color-dark t-font-semibold payment-status-form-head mt-5">
              {{ 'payment_link.title' | translate }}
            </h1>
            <p class="t-color-dark t-font-regular payment-status-form-subhead">
              {{ 'payment_link.sub_title' | translate }}
            </p>
            <button
              (click)="goToHome()"
              type="button"
              class="btn pay-submit-btn w-25 sf-pro-regular t-color-white t-bg-primary mb-5"
            >
              {{ 'pay_status.submit_button' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
