import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { DialogComponent } from './component/dialog/dialog.component';
import { DialogService } from './component/dialog/dialog.service';
import { FooterComponent } from './component/footer/footer.component';
import { LoaderComponent } from './component/loader/loader.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { PageTitleComponent } from './component/page-title/page-title.component';
import { PayStatusComponent } from './component/pay-status/pay-status.component';
import { PaymentLinkComponent } from './component/payment-link/payment-link.component';
import { PaymentStatusComponent } from './component/payment-status/payment-status.component';
import { SearchFilterPipe } from './custom-pipes/search-filter.pipe';
import { AlertComponent } from './dialog/alert/alert.component';
import { EllipsisDirective } from './directive/ellipsis.directive';

export function playerFactory(): any {
  return import('lottie-web');
}

// Create a loader for translations
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    DialogComponent,
    PageTitleComponent,
    PaymentStatusComponent,
    AlertComponent,
    SearchFilterPipe,
    NotFoundComponent,
    LoaderComponent,
    PageNotFoundComponent,
    EllipsisDirective,
    PayStatusComponent,
    PaymentLinkComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false, // Set to true if you want a separate translation instance for this module
    }),
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    PageTitleComponent,
    DialogComponent,
    TranslateModule,
    SearchFilterPipe,
    PaymentStatusComponent,
    LoaderComponent,
    NotFoundComponent,
    PageNotFoundComponent,
    EllipsisDirective,
  ],
})
export class GlobalModule {
  static forRoot(): ModuleWithProviders<GlobalModule> {
    return {
      ngModule: GlobalModule,
      providers: [DialogService],
    };
  }
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  public resolveComponent(component: any) {
    return this.componentFactoryResolver.resolveComponentFactory(component);
  }
}
