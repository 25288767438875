import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  // template: `
  // <div class="loader-container" [style.backgroundImage]="'url(' + backgroundImage + ')'">
  //     <ng-lottie [options]="options" width="320px"></ng-lottie>
  //     <div class="loader-text" *ngIf="loaderText">{{ loaderText }}</div>
  //   </div>
  // `,
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() loaderText?: string;
  @Input() backgroundImage?: string;
  @Input() minHeight?: string | number;

  options: AnimationOptions = {
    path: '/assets/loaders/gb-loader.json',
  };
}
