<div class="header-section">
  <div class="row">
    <div class="col-12">
      <div class="header-section-inner text-center">
        <h1 class="t-color-dark t-font-semibold t-font-header" *ngIf="title">
          {{ title }}
          <span>
            <button class="btn t-bg-white btn-back" (click)="goPreviousLocation()" *ngIf="isShow">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="arrow_back">
                  <mask
                    id="mask0_1_1042"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="18"
                    height="18"
                  >
                    <rect id="Bounding box" width="18" height="18" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1_1042)">
                    <path
                      id="arrow_back_2"
                      d="M9.00073 14.9937L3.00073 8.99365L9.00073 2.99365L10.0695 4.04365L5.86948 8.24365H15.0007V9.74365H5.86948L10.0695 13.9437L9.00073 14.9937Z"
                      fill="#1C1B1F"
                    />
                  </g>
                </g>
              </svg>
            </button>
          </span>
        </h1>
        <p class="t-color-dark t-font-medium t-font-subheader" *ngIf="subDetails">
          {{ subDetails }}
        </p>
      </div>
    </div>
  </div>
</div>
