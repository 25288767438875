import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order, Plan } from '../../models';
import { BrowserService, LocalStorageService, SubscriptionService } from '../../service';
import { AlertService } from '../../service/alert.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent implements OnInit {
  @Input() title!: string;
  @Input() subDetails!: string;
  @Input() backToRoute!: string;
  @Input() titleStyle!: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
  @Output() onBack: EventEmitter<any> = new EventEmitter();

  isShow = false;

  showBackBtnFor: Array<string> = ['/faq', '/plans', '/order-summary', '/help'];
  previousUrl: string | null = null;
  previousRoute!: string;
  selectedPlanDetails!: Plan;
  inProgress = false;
  orderObj!: Order;

  constructor(
    private location: Location,
    private router: Router,
    private browserService: BrowserService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private alertService: AlertService
  ) {
    // Checks if the current route is among the ones that should show the back button
    const url = location.path();
    this.isShow = this.showBackBtnFor.findIndex((option: string) => url.includes(option)) > -1;
  }

  ngOnInit(): void {
    // Checks if the browser is available
    this.browserService.getIsBrowser().subscribe();

    // Retrieves selected plan details and order object from local storage
    this.selectedPlanDetails = JSON.parse(this.localStorageService.getItem('planDetails') ?? '{}');
    this.orderObj = JSON.parse(this.localStorageService.getItem('orderObj') ?? '{}');
  }

  /**
   * Navigates to the previous location or a specific route.
   *
   * If backToRoute is provided, navigates to that route, handling special cases for 'plans'.
   * If backToRoute is not provided, navigates back to the previous page.
   */
  goPreviousLocation() {
    if (this.backToRoute) {
      // Navigates to the specified route, handling special cases for 'plans'
      const routeParams = [this.backToRoute];
      if (this.backToRoute === '/plans' && this.selectedPlanDetails?.selectedCountry) {
        this.localStorageService.removeItem('orderObj');
        this.localStorageService.removeItem('planDetails');
        // Calls function to unreserve inventories
        // this.unreservedInventories(this.orderObj?.reservedId);
        routeParams.push(this.selectedPlanDetails.selectedCountry);
      }
      this.router.navigate(routeParams);
    } else {
      // Navigates back to the previous page
      this.location.back();
    }
  }

  /**
   * Unreserve inventories with the given reservedId.
   *
   * @param reservedId - The ID of the reserved inventory
   */
  unreservedInventories(reservedId: string) {
    this.inProgress = true;
    this.subscriptionService.unreservedInventories(reservedId).subscribe(
      () => {
        this.inProgress = false;
      },
      (error: any) => {
        this.inProgress = false;
        this.alertService.error(error.error.message, error.status);
      }
    );
  }
}
