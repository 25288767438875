<div *ngIf="configData && configData?.agentId">
  <!-- Navbar - START -->
  <app-navbar></app-navbar>
  <!-- Navbar - END -->
  <div>
    <router-outlet></router-outlet>
  </div>
  <!-- Footer - START -->
  <app-footer></app-footer>
  <!-- Footer - END -->
</div>

<ng-container *ngIf="configData && configData?.status">
  <app-page-not-found></app-page-not-found>
</ng-container>

<ng-container *ngIf="!configData">
  <div class="w-auto mx-auto">
    <app-loader [backgroundImage]="'/assets/loaders/loader-1.png'"></app-loader>
  </div>
</ng-container>
