import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, switchMap } from 'rxjs';
import { BrowserService } from './browser.service';
import { EncryptionDecryptionService } from './encryption-decryption.service';
import { LocalStorageService } from './local-storage.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ColorsResponse {
  primary: string;
  secondary: string;
  tertiary: string;
  textSecondary: string;
  mutedBlock: string;
  danger: string;
  success: string;
}
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  serverUrl: string | undefined;
  private renderer: Renderer2;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configDetails$ = new BehaviorSubject<any>(undefined);
  private inProgressSubject = new BehaviorSubject<boolean>(false);
  inProgress$ = this.inProgressSubject.asObservable();

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private browserService: BrowserService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    browserService.getIsBrowser().subscribe((isBrowser) => {
      if (isBrowser) {
        this.saveInitialDataToLocalStorage();
      }
    });
  }

  /**
   * This function saves the initial data from SSR to local storage.
   */
  saveInitialDataToLocalStorage(): void {
    // Check if 'window' is defined and if '__INITIAL_DATA__' or '__ERROR_RESPONSE__' is present on the window object
    if (typeof window !== 'undefined') {
      if (window.__INITIAL_DATA__) {
        this.handleInitialData(window.__INITIAL_DATA__);
      } else if (window.__ERROR_RESPONSE__) {
        this.handleErrorResponse(window.__ERROR_RESPONSE__);
    }
  }
}

  /**
 * Handles the initial configuration data from the global window object.
 * @param initialData The encrypted initial configuration data.
 */
handleInitialData(initialData: any): void {
  try {
    // Retrieve and decrypt the initial configuration data
    const decryptedConfigData = this.encryptionDecryptionService.decryptData(initialData);

    // Encrypt relevant part of decrypted data and store in localStorage
    const encryptedConfigData = EncryptionDecryptionService.encryptData(decryptedConfigData);
    this.localStorageService.setItem('config', JSON.stringify(encryptedConfigData));

    // Store the region data in localStorage
    this.localStorageService.setItem('region', JSON.stringify(decryptedConfigData?.region));

    // Update the observable with the decrypted configuration data
    this.configDetails$.next(decryptedConfigData);

    // Clean up by removing the initial data from the global window object
    delete window.__INITIAL_DATA__;
  } catch (error) {
      console.error('Error while processing initial data:', error);
  }
}

/**
 * Handles the error response from the global window object.
 * @param errorResponse The error response object.
 */
private handleErrorResponse(errorResponse: any): void {
  try {
    // Attempt to retrieve encrypted config data from localStorage
    const storedConfigData = this.localStorageService.getItem('config');
    if (storedConfigData) {
      const configData = this.decryptStoredConfigData(storedConfigData);

      if (configData) {
        // Update observable with decrypted configuration data
        this.configDetails$.next(configData);
      } else {
        // Handle decryption failure
        this.configDetails$.next(errorResponse);
      }
    } else {
      // If no config data found in localStorage, use the error response directly
      this.configDetails$.next(errorResponse);
    }

    // Clean up by removing the stored error response from the global window object
    delete window.__ERROR_RESPONSE__;
  } catch (error) {
    console.error('Error while processing error response:', error);
  }
}

/**
 * Decrypts the stored configuration data from localStorage.
 * @param storedData The encrypted configuration data stored in localStorage.
 * @returns The decrypted configuration data or null if decryption fails.
 */
private decryptStoredConfigData(storedData: string | null): any {
  try {
    return storedData ? this.encryptionDecryptionService.decryptData(JSON.parse(storedData)) : null;
  } catch (error) {
    console.error('Error while decrypting stored config data:', error);
    return null;
  }
}


  /**
   * Retrieves configuration details from the server.
   * @deprecated
   */
  getConfigFromServer() {
    this.showLoader();

    // Subscribe to queryParams observable and switch to API call
    this.route.queryParams
      .pipe(
        switchMap((params) => {
          const configData = JSON.parse(this.localStorageService.getItem('config') ?? '{}');

          const agentId = params['agentId'] || configData?.agentId;
          const customerId = params['customerId'] || configData?.customerId;
          this.configDetails$.next(undefined);

          let apiUrl = `${this.serverUrl}/getagentconfiguration/?`;

          // Conditionally include agentId in the API URL
          if (agentId) {
            apiUrl += `agentId=${agentId}&`;
          }

          // Conditionally include customerId in the API URL
          if (customerId) {
            apiUrl += `customerId=${customerId}&`;
          }

          // Conditionally include cacheId in the API URL
          if (configData?.cacheId) {
            apiUrl += `cacheId=${configData.cacheId}&`;
          }

          // Remove the trailing '&' if it exists
          apiUrl = apiUrl.replace(/&$/, '');

          // Call the API and return the observable
          return this.http.get(apiUrl);
        })
      )
      .subscribe(
        (result: any) => {
          const configData = JSON.parse(this.localStorageService.getItem('config') ?? '{}');

          if (result?.data?.cacheId) {
            this.localStorageService.setItem('config', JSON.stringify(result.data));
            this.configDetails$.next(result.data);
          } else if (configData?.cacheId) {
            this.configDetails$.next(configData);
          }

          this.updateColors(
            result?.data?.trsWebAppConfig?.colors || configData?.trsWebAppConfig?.colors
          );
        },
        (error) => {
          this.configDetails$.next(error);
        }
      );
  }

  /**
   * Retrieves locally stored configuration details.
   *
   * @returns An observable of the local configuration details.
   */
  getLocalConfig() {
    return this.configDetails$.asObservable();
  }

  /**
   * Updates the application colors based on the provided color settings.
   *
   * @param colors The color settings to update.
   */
  public updateColors(colors: []): void {
    // Access the root element and print its styles
    const root = this.document.querySelector(':root');

    if (root) {
      // Map the API response keys to the CSS variable names
      const variableMappings: { [key: string]: string } = {
        primary: '--t-color-primary',
        secondary: '--t-color-secondary',
        tertiary: '--t-dark-color',
        textSecondary: '--t-dark-secondary-color',
        mutedBlock: '--t-color-muted',
        danger: '--t-color-danger',
        success: '--t-color-success',
      };

      // Update each color variable
      Object.keys(variableMappings).forEach((key: string) => {
        const variableName = variableMappings[key];
        const value = colors[key as keyof typeof colors]; // Add type annotation to fix the problem
        this.renderer.setStyle(root, variableName, value);
      });

      // Log the computed styles
      // const computedStyles = getComputedStyle(root);
    }
  }

  /**
   * Displays the loader.
   */
  showLoader() {
    this.inProgressSubject.next(true);
  }

  /**
   * Hides the loader.
   */
  hideLoader() {
    this.inProgressSubject.next(false);
  }
}
