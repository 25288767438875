import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubscriptionDetailsResponse } from '../component';
import { ConfigData } from '../models';
import { BrowserService } from './browser.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  serverUrl: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  isBrowser: boolean = false;

  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private router: Router,
    private configService: ConfigService
  ) {
    // Subscribe to browser service to determine if the code is running in a browser
    browserService.getIsBrowser().subscribe((isBrowser: boolean) => (this.isBrowser = isBrowser));

    // Subscribe to local config service to get server URL
    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.serverUrl = configData?.trsWebAppConfig?.serverUrl;
    });
  }

  /**
   * Subscribes to a plan.
   *
   * @param agentId The ID of the agent.
   * @param reservedId The ID of the reservation.
   * @param traveller The traveller details.
   * @returns A promise that resolves if the subscription is successful, and rejects if there's an error.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribePlan(agentId: any, reservedId: any, traveller: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.serverUrl}/checkout?reservedId=${reservedId}&agentId=${agentId}`, traveller)
        .subscribe(
          (res: any) => {
            if (res?.session) {
              if (this.isBrowser && res?.session.url?.trim() != '') {
                window.location.href = res?.session.url;
              }
              resolve(true);
            }
          },
          (err: any) => {
            reject(new Error(err));
          }
        );
    });
  }

  /**
   * Checks the availability of an eSIM.
   *
   * @param agentId The ID of the agent.
   * @param traveller The traveller details.
   * @param currentLang The current language.
   * @returns An observable containing the eSIM availability information.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkEsimAvailability(agentId: any, traveller: any, currentLang: string) {
    return this.http.post(
      `${this.serverUrl}/subscriptions/checkEsimAvailabilty?agentId=${agentId}&language=${currentLang}`,
      traveller
    );
  }

  /**
   * Unreserve inventories for a given reservation ID.
   *
   * @param reservedId The ID of the reservation to unreserve inventories for.
   *
   * @returns An observable indicating the success or failure of the unreservation operation.
   */
  unreservedInventories(reservedId: any) {
    return this.http.delete(`${this.serverUrl}/inventory/unReserve?reservedId=${reservedId}`);
  }

  /**
   * Retrieves subscription details for a given transaction ID.
   *
   * @param transactionId The transaction ID.
   * @param currentLang The current language.
   * @returns An observable containing subscription details.
   */
  getSubscriptionDetails(
    transactionId: string,
    currentLang: string
  ): Observable<SubscriptionDetailsResponse> {
    return this.http.get<SubscriptionDetailsResponse>(
      `${this.serverUrl}/subscription?transactionId=${transactionId}&language=${currentLang}`
    );
  }
}
