import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigData } from '../../models';
import { ConfigService, LanguageService, LocalStorageService } from '../../service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  configData!: ConfigData;
  languages: {
    name?: string | undefined;
    code?: string | undefined;
    _id?: string | undefined;
  }[] = [];
  defaultLanguage = 'en';
  selectedLanguage!: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private languageService: LanguageService,
    public configService: ConfigService,
    private localStorageService: LocalStorageService
  ) {
    // Retrieves local configuration data
    configService.getLocalConfig().subscribe((configData: ConfigData) => {
      this.configData = configData;

      // Retrieves supported languages and default language from configuration data
      this.languages = this.configData?.trsWebAppConfig?.languages?.supported || [];
      this.defaultLanguage = this.configData?.trsWebAppConfig?.languages?.default ?? '';

      // Retrieves selected language from local storage or sets default language
      this.selectedLanguage = this.localStorageService.getItem('language') ?? this.defaultLanguage;
    });
  }

  /**
   * Changes the language of the application.
   *
   * @param lang The language code to switch to.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeLanguage(lang: any): void {
    this.selectedLanguage = lang ?? 'en';

    // Saves selected language to local storage
    this.localStorageService.setItem('language', this.selectedLanguage);

    // Sets the language using LanguageService
    this.languageService.setLanguage(this.selectedLanguage);
  }

  /**
   * Hides the navbar dropdown menu.
   */
  hideDropdown() {
    const element = document.getElementById('navbarSupportedContent');
    element?.classList.remove('show');
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleKeyDown(event: KeyboardEvent) {}
}
