import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BrowserService,
  ConfigService,
  LanguageService,
  LocalStorageService,
} from './global/service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'gb-travel-webapp-frontend';
  isBrowser = false;
  selectedLanguage: any;
  defaultLanguage = 'en';
  languageLoading = true;
  configData: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private browserService: BrowserService,
    private configService: ConfigService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    public languageService: LanguageService
  ) {
    // Checks if running in a browser environment
    this.isBrowser = isPlatformBrowser(platformId);

    // Sets browser status using browser service
    browserService.setIsBrowser(this.isBrowser);
  }

  /**
   * Initializes the component and sets up language preferences.
   */
  ngOnInit(): void {
    this.selectedLanguage = this.localStorageService.getItem('language');

    // Sets up language preferences
    this.translateService.addLangs(['en', 'es', 'fr']);
    this.translateService.setDefaultLang(this.defaultLanguage);

    // Subscribes to language change and updates languageLoading status
    this.translateService.use(this.selectedLanguage || this.defaultLanguage).subscribe(() => {
      setTimeout(() => {
        this.languageLoading = false;
      }, 1000);
    });
  }
}
