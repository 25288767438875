import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appEllipsis]',
})
export class EllipsisDirective {
  @Input() limit = 50;
  @Input() limitMob = 100;
  @Input() placement = 'top';
  @Input() delay = '300';
  @Input() offset = '0';
  @Input() adjustLimit = false;

  tooltip!: HTMLElement | undefined;
  tooltipTitle!: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.applyEllipsis();
  }

  ngOnChanges() {
    this.applyEllipsis();
  }

  /**
   * Event listener for mouse enter to show tooltip.
   */
  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  /**
   * Event listener for mouse leave to hide tooltip.
   */
  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  /**
   * Applies ellipsis to the text content of the host element if it exceeds the character limit.
   */
  private applyEllipsis() {
    const text = this.el.nativeElement.innerText;
    let limitToUse = this.limit;

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if(screenWidth < 575){
      limitToUse = this.limitMob
    }

    if (text.length > limitToUse) {
      this.tooltipTitle = text;

      if (this.tooltipTitle && this.adjustLimit) {
        const screenWidth =
          window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        // Adjust the limit based on the screen width
        if (screenWidth === 1440) {
          limitToUse = Math.ceil(limitToUse * 0.75);
        }
      }
      const truncatedText = text.slice(0, limitToUse);
      this.renderer.setProperty(this.el.nativeElement, 'innerText', truncatedText + '...');
    }
  }

  /**
   * Creates and displays the tooltip.
   */
  private show() {
    if (this.tooltipTitle) {
      this.create();
      this.setPosition();
      this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
    }
  }

  /**
   * Hides and removes the tooltip.
   */
  private hide() {
    if (this.tooltip) {
      this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this; // Store reference to 'this' for use inside setTimeout
      window.setTimeout(() => {
        if (self.tooltip) {
          self.renderer.removeChild(document.body, self.tooltip);
          self.tooltip = undefined;
        }
      }, parseInt(self.delay));
    }
  }

  /**
   * Creates the tooltip element and appends it to the document body.
   */
  private create() {
    this.tooltip = this.renderer.createElement('span');
    this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipTitle));

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  /**
   * Positions the tooltip relative to the host element.
   */
  private setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip?.getBoundingClientRect();
    const scrollPos =
      window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let top, left;

    if (this.placement === 'top' && tooltipPos) {
      top = hostPos.top - tooltipPos.height - parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom' && tooltipPos) {
      top = hostPos.bottom + parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - parseInt(this.offset);
    }

    if (this.placement === 'right' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + parseInt(this.offset);
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
